import React from "react";
import { NavLink } from "react-router-dom";
import "./../../pages/admin/adminMenu.css";

const Menu = ({ user, menuAnimation }) => {
  /* Responsive & Mobile Navigation Menus */
  return (
    <div className={`${menuAnimation} menu-container`}>
      <nav>
        {/* User Menu */}

        <div
          className={
            user.role !== "location" ? "menu-user menu-adjust" : "menu-user"
          }
        >
          <h3>
            <span className="menu-design">
              {user && user.firstName ? `${user.firstName} ` : "User Portal"}
            </span>
          </h3>
          <ul className="nav flex-row justify-content-center">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/admin/dashboard"
                activeclassname="active"
              >
                <i className="fas fa-home">
                  {" "}
                  <span className="menu-names">Dashboard</span>
                </i>
              </NavLink>
            </li>
            {user && user.role !== "location" && user.role !== "api-access" && (
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/profile"
                  activeclassname="active"
                >
                  <i className="far fa-user">
                    {" "}
                    <span className="menu-names">My Profile</span>
                  </i>
                </NavLink>
              </li>
            )}
            {user && user.role !== "api-access" && (
              <>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/admin/my-locations"
                    activeclassname="active"
                  >
                    <i className="fas fa-map-marker-alt">
                      <span className="menu-names">My Locations</span>
                    </i>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/admin/help"
                    activeclassname="active"
                  >
                    <i className="fas fa-question">
                      <span className="menu-names">Support</span>
                    </i>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* Admin Menu */}

        {user && (user.role === "admin" || user.role === "super-admin") && (
          <div className="menu-admin">
            <h3>
              <span className="menu-design">Admin Controls</span>
            </h3>
            <ul className="nav flex-row justify-content-center">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/locations"
                  activeclassname="active"
                >
                  <i className="fas fa-map">
                    <span className="menu-names">Locations</span>
                  </i>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/rounds"
                  activeclassname="active"
                >
                  <i className="far fa-bell">
                    <span className="menu-names">Rounds</span>
                  </i>
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        {/* Super-Admin Menu */}

        {user && user.role === "super-admin" ? (
          <div className="menu-super-admin">
            <h3>
              <span className="menu-design">Super Admin</span>
            </h3>
            <ul className="nav flex-row justify-content-center">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/users"
                  activeclassname="active"
                >
                  <i className="fas fa-users">
                    <span className="menu-names">Users</span>
                  </i>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/logs"
                  activeclassname="active"
                >
                  <i className="fas fa-globe">
                    <span className="menu-names">Logs</span>
                  </i>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/admin/releases"
                  activeclassname="active"
                >
                  <i className="fas fa-rss">
                    <span className="menu-names">Releases</span>
                  </i>
                </NavLink>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </nav>
    </div>
  );
};

export default Menu;
