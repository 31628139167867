import React from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import PrivateRoute from "./components/routing/PrivateRoute";
import AuthState from "./context/auth/AuthState";
import ScreenState from "./context/screen/ScreenState";
import LocationState from "./context/location/LocationState";
import NewScreen from "./pages/screen/NewScreen";
import AdminPage from "./pages/admin/AdminPage";
import Login from "./pages/login/Login";
import LoginSSO from "./pages/login/LoginSSO";

function App() {
  return (
    <div>
      <AuthState>
        <ScreenState>
          <LocationState>
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/login/:token" element={<LoginSSO />} />
                <Route path="/screens" element={<NewScreen />} />
                <Route element={<PrivateRoute />}>
                  <Route index element={<Navigate replace to="/admin" />} />
                  <Route path="*" element={<AdminPage />} />
                </Route>
                <Route path="*" element={<Navigate replace to="/login" />} />
              </Routes>
            </Router>
          </LocationState>
        </ScreenState>
      </AuthState>
    </div>
  );
}

export default App;
