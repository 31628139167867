import {
  USER_ERROR,
  GET_USERS,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  SET_CURRENT,
  CLEAR_CURRENT,
} from "./userTypes";
const userReducer = (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case USER_ERROR:
      return { ...state, error: action.payload };
    case ADD_USER:
      return {
        ...state,
        loading: false,
        current: action.payload,
        users: [action.payload, ...state.users],
        error: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        error: null,
        current: action.payload,
        loading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((contact) => contact._id !== action.payload),
        loading: false,
        error: null,
      };
    case SET_CURRENT:
      return {
        ...state,
        error: null,
        current: action.payload,
        loading: false,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        error: null,
        current: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
