import React, { useReducer } from "react";
import ScreenContext from "./ScreenContext";
import screenReducer from "./screenReducer";
import {
  CREATE_SCREEN,
  SCREEN_ERROR,
  GET_SCREENS,
  GET_WORKOUT,
  GET_SCREEN,
  CLEAR_SCREENS,
  CLEAR_SCREEN,
  SET_SCREEN_AUTH,
  REMOVE_SCREEN,
} from "./screenTypes";
import axios from "axios";
import setAuthToken, { setHeader } from "../../utils/setAuthToken";

const ScreenState = (props) => {
  const initialState = {
    workout: null,
    isLrVideo: false,
    error: null,
    screen: null,
    loading: true,
    endDate: null,
    isScreenAuth: false,
    authToken: localStorage["screen_auth_token"],
    screens: [],
  };

  const [state, dispatch] = useReducer(screenReducer, initialState);

  //#region Screen Methods

  //#region Create

  // Create Screen to connect
  const createScreen = async (screen) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.post(`/api/v1/screens`, screen);

      // if (!!res.data.data) return null;

      dispatch({ type: CREATE_SCREEN, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, message: error.message });
    }
  };

  //#endregion

  //#region Read

  // Get Screens
  const getScreens = async () => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(`/api/v1/screens`);

      dispatch({ type: GET_SCREENS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, payload: error.message });
    }
  };

  /**
   * Get Screen by id
   * @param {String} id id of the screen to be returned
   */
  const getScreen = async (id) => {
    try {
      const res = await axios.get(`/api/v1/screens/${id}`);

      dispatch({ type: GET_SCREEN, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, payload: error.message });
    }
  };

  // Get today's workout
  const getCurrentRoundWorkout = async (roundNumber) => {
    try {
      const res = await axios.get(`/api/v1/screens/round/${roundNumber}`);

      if (!res.data.data) return null;

      dispatch({ type: GET_WORKOUT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, payload: error.message });
    }
  };

  /**
   * Get screen by token
   * @param {String} token auth token from local storage
   */
  const getScreenByToken = async () => {
    const token = localStorage.screen_auth_token;

    if (!token) return dispatch({ type: GET_SCREEN, payload: null });

    setHeader("screen-auth-token", token);

    try {
      const res = await axios.get(`/api/v1/screens/auth`);

      dispatch({ type: GET_SCREEN, payload: res.data.data });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        delete localStorage.screen_auth_token;
        setAuthToken(false);
        dispatch({ type: SCREEN_ERROR });
      }
      dispatch({ type: SCREEN_ERROR, message: error.message });
    }
  };

  //#endregion

  //#region  Update

  // link screen
  const linkScreen = async (screen, authCode) => {
    try {
      let res = await axios.put(`/api/v1/screens/link/${screen._id}`, {
        authCode,
      });

      dispatch({ type: GET_SCREEN, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, message: error.message });
    }
  };

  // updateScreen
  const updateScreen = async (screen) => {
    setAuthToken(localStorage.token);

    try {
      let res = await axios.put(`/api/v1/screens/${screen._id}`, {
        ...screen,
        refreshFlag: true,
      });
      
      dispatch({ type: GET_SCREEN, payload: res.data.data });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, message: error.message });
    }
  };

  // Screen Status & workout check and update
  const sendScreenStatus = async () => {
    try {
      const token = localStorage.screen_auth_token;

      if (!token) return dispatch({ type: GET_SCREEN, payload: null });

      setHeader("screen-auth-token", token);

      const res = await axios.patch(`/api/v1/screens/auth`, {
        status: {
          label: "connected",
          isConnected: true,
          statusUpdatedAt: new Date(),
        },
        refreshFlag: false,
      });

      const { screen, workout, lrVideo, appVersion } = res.data.data;

      // Checks if stored appVersion is different an hard reloads the page if different
      checkAndUpdateAppVersion(appVersion);

      // TODO: add the logic for forced reload outside of checking the release version.

      // Check if forced reload or appversion is old and force reload
      // if (hardReload === true) {
      //   console.log("Hard Reload");
      //   hardReloadScreen();
      // }

      if (screen.refreshFlag === true) {
        dispatch({ type: CLEAR_SCREEN });
      }

      // Check if state workout is same as server workout
      if (
        state.workout.id !== workout.id ||
        new Date(state.workout.updatedAt) < new Date(workout.updatedAt)
      ) {
        dispatch({ type: GET_WORKOUT, payload: { workout, lrVideo } });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        delete localStorage.screen_auth_token;
        setIsScreenAuth(false);
        dispatch({ type: SCREEN_ERROR });
      }
      dispatch({ type: SCREEN_ERROR, message: error.message });
    }
  };
  //#endregion

  //#region Delete
  const removeScreen = async (id) => {
    setAuthToken(localStorage.token);
    try {
      await axios.delete(`/api/v1/screens/${id}`);

      dispatch({ type: REMOVE_SCREEN, payload: id });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, payload: error.msg });
    }
  };
  //#endregion

  //#region Other Methods

  // Refresh screen
  const refreshScreen = async (screen) => {
    try {
      // Update screen
      await axios.put(`/api/v1/screens/${screen._id}`, {
        refreshFlag: true,
      });
    } catch (error) {
      dispatch({ type: SCREEN_ERROR, message: error.message });
    }
  };

  // Hard Reload of the site
  const hardReloadScreen = (clearCache) => {
    // Reload page and clear cached images, css files, and js files
    window.location.reload(clearCache);
    return false;
  };

  // Check and update app version
  const checkAndUpdateAppVersion = (appVersion = null) => {
    if (!appVersion) return;
    if (!localStorage.app_version || appVersion !== localStorage.app_version) {
      localStorage.app_version = appVersion;
      hardReloadScreen(true);
    }
  };

  // Clear screens
  const clearScreens = () => {
    dispatch({ type: CLEAR_SCREENS, payload: [] });
  };

  // Clears current screen
  const clearScreen = () => {
    dispatch({ type: CLEAR_SCREEN });
  };

  // Sets the isScreenAuth state
  const setIsScreenAuth = (isScreenAuth) => {
    dispatch({ type: SET_SCREEN_AUTH, payload: isScreenAuth });
  };

  //#endregion

  //#endregion

  return (
    <ScreenContext.Provider
      value={{
        screen: state.screen,
        authToken: state.authToken,
        screens: state.screens,
        workout: state.workout,
        error: state.error,
        loading: state.loading,
        endDate: state.endDate,
        isScreenAuth: state.isScreenAuth,
        isLrVideo: state.isLrVideo,
        setIsScreenAuth,
        getScreenByToken,
        linkScreen,
        getCurrentRoundWorkout,
        getScreen,
        createScreen,
        refreshScreen,
        getScreens,
        clearScreens,
        sendScreenStatus,
        clearScreen,
        updateScreen,
        removeScreen,
      }}
    >
      {props.children}
    </ScreenContext.Provider>
  );
};

export default ScreenState;
