import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Orientation from "./Orientation";
import "./../../pages/admin/adminMenu.css";

const Navbar = ({ logout, user }) => {
  // Handle Menus in State
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(false);
  const [expandMenu, setExpandMenu] = useState("");

  const [dropdown, setDropDown] = useState("");

  const handleTransition = (menu) => {
    if (!open) setTransition(menu);
  };

  // Handle expanding menu
  const handleClick = () => {
    if (dropdown === " menu-show") {
      return handleAnimation();
    } else if (dropdown === "") {
      setDropDown(" menu-show");
      setExpandMenu("menu-expanded");
    }
    return 0;
  };

  //Handle retracting menu
  const handleAnimation = () => {
    setExpandMenu("menu-expanded-after");
    const closeMenu = setTimeout(() => {
      setDropDown("");
      setExpandMenu("");
    }, 80);
    return () => clearTimeout(closeMenu);
  };

  const onLogout = () => {
    logout();
  };

  return (
    <>
      <div className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <a
          className="navbar-brand col-md-3 col-lg-2 mr-0 px-3"
          style={{ textShadow: "1px 1px 1px black" }}
          href="/admin"
        >
          <img
            src="https://res.cloudinary.com/ilovekickboxing/image/upload/v1730999214/ilkb-logo-white_iu3iwh.png"
            alt="ILKB logo"
            style={{
              maxWidth: "calc(25% + 8rem)",
              marginLeft: "calc(3% - .5rem)",
              padding: "0%",
            }}
          />
        </a>
        <button
          className={
            "navbar-toggler py-2 position-absolute d-md-none collapsed"
          }
          type="button"
          data-toggle="collapse"
          data-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => handleTransition(!transition)}
        >
          <span style={{ fontSize: "larger" }}>
            {!transition && !open ? (
              <i className="fas fa-bars"></i>
            ) : (
              <i className="fas fa-minus"></i>
            )}
          </span>
        </button>
        {window.innerWidth < 768 && transition && (
          <Orientation
            user={user}
            open={open}
            transition={transition}
            setTransition={setTransition}
            setOpen={setOpen}
          />
        )}
        <div className="navbar-nav px-3" style={{ flexDirection: "row" }}>
          <div className="nav-item text-nowrap" style={{ marginLeft: "1.5vw" }}>
            <a
              className="nav-link"
              aria-label="navigation-sign-out"
              onClick={onLogout}
              href="/login"
            >
              <i className="fas fa-sign-out-alt"></i> Sign out
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
