import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import WorkoutContext from "../../../context/workout/WorkoutContext";
import BackButton from "../../common/BackButton";
import AlertContext from "../../../context/alert/AlertContext";
import WorkoutRoutineList from "./WorkoutRoutineList";
import Spinner from "../../layout/Spinner";
import LockButton from "../../common/LockButton";

const WorkoutItem = ({ props }) => {
  const params = useParams();
  const workoutId = params ? params.id : undefined;
  const roundNumber = params ? params.roundNumber : undefined;
  const videoLinkBaseURL = "https://d2567ph5zp6ttk.cloudfront.net/";

  const workoutContext = useContext(WorkoutContext);
  const alertContext = useContext(AlertContext);
  const {
    getWorkout,
    addWorkout,
    error,
    updateWorkout,
    current,
    clearCurrent,
    loading,
    addWorkoutRoutine,
    updateWorkoutRoutine,
    deleteWorkoutRoutine,
  } = workoutContext;
  const { setAlert } = alertContext;

  const [update, setUpdate] = useState(false);
  const [updatedWorkout, setUpdatedWorkout] = useState(null);
  const [locked, setLocked] = useState(true);

  useEffect(() => {
    if (!current && workoutId) {
      getWorkout(workoutId);
    } else if (current && !workoutId && !updatedWorkout) {
      getWorkout(current.id);
      setUpdatedWorkout(current.id);
    }

    if (current) {
      handleWorkout(current);
    } else {
      handleWorkout({
        startDate: "",
        endDate: "",
        name: "",
        subHeader: "",
        round: roundNumber,
        routines: [],
      });
    }

    // eslint-disable-next-line
  }, [error, current, loading]);

  // On unmount remove current
  useEffect(() => {
    return () => clearCurrent();
    // eslint-disable-next-line
  }, []);

  const [workoutInput, setWorkoutInput] = useState({
    startDate: "",
    endDate: "",
    name: "",
    subHeader: "",
    round: roundNumber,
    routines: [],
  });


  const [formErrors, setFormErrors] = useState([]);
  const [routineErrors, setRoutineErrors] = useState([]);
  
  const handleWorkout = (workoutInput) =>
    setWorkoutInput({ ...current, ...workoutInput });
  
  const { name, startDate, endDate, routines } = workoutInput;

  const [routineData, setRoutineData] = useState(routines);

  useEffect(() => {
    setRoutineData(routines);
  }, [routines])

  // validation for video link URLs
  const validateRounds = () => {
    if (!routineData) return false;

    let errors = [];
    let emptyString = false;
    let invalidString = false;

    for (const routine of routineData) {
      if (!routine.videoLink.length) {
        emptyString = true;
        errors.push(routine._id);
      } else if (routine.videoLink.indexOf(videoLinkBaseURL) === -1) {
        invalidString = true;
        errors.push(routine._id);
      }
    }
    
    if (errors.length > 0) {
      setRoutineErrors(
        errors
      );
      if (invalidString) {
        setAlert({
          message: "Invalid video URLs found. Make sure you're using approved links.",
          type: "error"
        });
      }
      if (emptyString) {
        setAlert({
          message: "Video link URLs cannot be empty.",
          type: "error"
        });
      }
      return false;
    }
    return true;
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!validateRounds()) return;

    if (formErrors.length > 0) {
      setAlert({
        message:
          "Please double-check the form requirements and try again. The Workout end date cannot be scheduled before the start date.",
        type: "error",
      });
    } else if (workoutId) {
      setUpdate(false);
      updateWorkout({
        ...workoutInput,
        routines: routineData,
        endDate: `${endDate.split("T")[0]}T23:59:59.999Z`,
      });
      setAlert({
        message: `Updated Workout: ${workoutInput.name}`,
        type: "success",
      });
      setLocked(true);
    } else if (updatedWorkout) {
      setUpdate(false);
      updateWorkout({
        ...workoutInput,
        routines: routineData,
        endDate: `${endDate.split("T")[0]}T23:59:59.999Z`,
      });
      setAlert({
        message: `Updated Workout: ${workoutInput.name}`,
        type: "success",
      });
      setLocked(true);
    } else {
      setUpdate(false);
      addWorkout({
        ...workoutInput,
        endDate: `${endDate.split("T")[0]}T23:59:59.999Z`,
      });
      setAlert({
        message: `Added Workout: ${workoutInput.name}`,
        type: "success",
      });
      setLocked(true);
    }
  };

  const handleRoutineUpdate = (newRoutine) => {   
    let newRoutines = routineData;
    newRoutines = newRoutines.map((r) =>
      r._id === newRoutine._id ? newRoutine : r
  )
    setRoutineData(newRoutines);
  }

  const onChange = (event) => {
    if (current && event.target.value !== current[event.target.name]) {
      setUpdate(true);
    } else {
      update && setUpdate(false);
    }

    setWorkoutInput({
      ...workoutInput,
      [event.target.name]: event.target.value,
    });

    setFormErrors([...formErrors]);

    if (["startDate", "endDate"].includes(event.target.name)) {
      let startDateObj = new Date(workoutInput.startDate);
      let endDateObj = new Date(workoutInput.endDate);
      if (event.target.name === "startDate") {
        startDateObj = new Date(event.target.value);
      }
      if (event.target.name === "endDate") {
        endDateObj = new Date(event.target.value);
      }
      if (startDateObj > endDateObj) {
        setFormErrors(["endDate"]);
      } else {
        setFormErrors(
          formErrors.filter((err) => {
            return err !== "endDate";
          }),
        );
      }
    }
  };

  return (
    <>
      {workoutInput && !loading && (
        <>
          <form onSubmit={onSubmit}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h2 className="text-break">
              <LockButton locked={locked} setLocked={setLocked} />{" "}{current ? current.name : "Schedule New Workout"}
              </h2>
              <div className="btn-group ">
                <BackButton url={`/admin/rounds/${roundNumber}/workout`} />
                {locked ||
                  <button type="submit" className="btn btn-primary" disabled={locked}>
                    <i className="fas fa-save"></i> {!current ? "Save" : "Update"}
                  </button>
                }
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  maxLength={85}
                  className="form-control"
                  value={name || ""}
                  placeholder="85 characters (max)"
                  onChange={onChange}
                  required={true}
                  disabled={locked}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-3">
                <label htmlFor="startDate">Start Date</label>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  onChange={onChange}
                  value={startDate.split("T")[0] || ""}
                  required={true}
                  disabled={locked}
                />
              </div>
              <div className="form-group col-3">
                <label htmlFor="endDate">End Date</label>
                <input
                  className={`form-control ${
                    formErrors && formErrors.includes("endDate")
                      ? "is-invalid"
                      : ""
                  }`}
                  type="date"
                  name="endDate"
                  onChange={onChange}
                  value={endDate.split("T")[0] || ""}
                  required={true}
                  disabled={locked}
                />
                {formErrors && formErrors.includes("endDate") ? (
                  <div className="text-9r-red">
                    Please enter a valid end date.
                  </div>
                ) : null}
              </div>
            </div>
          </form>
          {update && (
            <p
              className="alert alert-warning"
              role="alert"
              style={{
                padding: "10px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              <b>You have unsaved changes!</b> Please click the Update button to
              save your changes.
            </p>
          )}
          {routines && current && (
            <WorkoutRoutineList
              routines={routines}
              workoutInput={workoutInput}
              updateWorkout={updateWorkout}
              setAlert={setAlert}
              addWorkoutRoutine={addWorkoutRoutine}
              handleRoutineUpdate={handleRoutineUpdate}
              deleteWorkoutRoutine={deleteWorkoutRoutine}
              locked={locked}
              errors={routineErrors}
            />
          )}
        </>
      )}

      {(!workoutInput || loading) && <Spinner />}
    </>
  );
};

export default WorkoutItem;
