import React from "react";
import { Link } from "react-router-dom";
import "../../admin-page/workout/z_overwrite.css";

const ContentStatusListItem = ({
  workout,
}) => {


  return (
    <>
      {workout && (
        <tr className="workout-item-wrapper">
          <td>{workout.name}</td>
          <td>{workout.startDate}</td>
          <td>{workout.endDate}</td>
          <td className="text-danger">Missing Video Links</td>
          <td>
            <div className="btn-group btn-group-sm">
              <Link
                className="btn btn-outline-warning"
                to={`/admin/rounds/1/workout/${workout.id}`}
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default ContentStatusListItem;
