import { GET_LOGS, DELETE_LOGS, LOGS_ERROR } from "./loggerTypes";
const loggerReducer = (state, action) => {
  switch (action.type) {
    case GET_LOGS:
      return {
        ...state,
        logs: action.payload,
        loading: false,
      };
    case DELETE_LOGS:
      return {
        ...state,
        logs: [],
        loading: false,
      };
    case LOGS_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default loggerReducer;
