import {
  GET_RELEASES,
  ADD_RELEASE,
  RELEASE_ERROR,
  GET_RELEASE,
  CLEAR_CURRENT,
} from "./releaseTypes";
import { getNewestReleases } from "../../utils/releaseControls";

const releaseReducer = (state, action) => {
  switch (action.type) {
    case GET_RELEASE:
      return {
        ...state,
        error: null,
        current: action.payload,
      };
    case GET_RELEASES:
      return {
        ...state,
        releases: action.payload,
        newest: getNewestReleases(action.payload, 4),
        loading: false,
        error: null,
      };
    case ADD_RELEASE:
      return {
        ...state,
        current: action.payload,
        releases: [action.payload, ...state.releases],
        loading: false,
        error: null,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        error: null,
        current: null,
      };
    case RELEASE_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default releaseReducer;
