import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/auth/AuthContext";
import Spinner from "../../layout/Spinner";

const Profile = () => {
  const authContext = useContext(AuthContext);
  const {
    loadUser,
    user,
    loading,
    error,
    //updateMe
  } = authContext;

  useEffect(() => {
    loadUser();
    if (user) {
      setUserState(user);
    }
    // eslint-disable-next-line
  }, [error, loading]);

  // FIXME: fix reset password
  // const [resetPassword, setResetPassword] = useState(false);
  // const handleResetPasswordOff = () => setResetPassword(false);
  // const handleResetPasswordOn = () => setResetPassword(true);

  const [userState, setUserState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    password: "",
    confirmPassword: "",
  });

  const {
    firstName,
    lastName,
    email,
    role,
    //confirmPassword,
    // password
  } = userState;

  // FIXME: Need to fix password
  // const onChange = (e) => {
  //   setUserState({ ...userState, [e.target.name]: e.target.value });
  // };

  // const onSubmit = (event) => {
  //   event.preventDefault();

  //   if (confirmPassword !== password) {
  //     return window.alert("Password and confirm Password must be equal");
  //   }
  //   updateMe(userState);

  //   setUserState({ ...userState, password: "", confirmPassword: "" });

  //   handleResetPasswordOff();
  // };

  // FIXME: Need to fix password reset
  // const onClick = (e) => {
  //   e.preventDefault();

  //   handleResetPasswordOff();
  // };

  return (
    <form //onSubmit={onSubmit}
    >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>Profile</h2>
      </div>
      {user && !loading ? (
        <>
          <div className="row">
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="firstName">First Name</label>
              <input
                name="firstName"
                type="text"
                className="form-control"
                value={firstName}
                // onChange={onChange}
                required={true}
                disabled={true}
              />
            </div>
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="lastName">Last Name</label>
              <input
                name="lastName"
                type="text"
                className="form-control"
                value={lastName}
                // onChange={onChange}
                required={true}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="email">Email</label>
              <input
                name="email"
                type="email"
                className="form-control"
                value={email}
                // onChange={onChange}
                disabled={true}
              />
            </div>
            <div className="form-group col-md-3 col-sm-12">
              <label htmlFor="role">Role</label>
              <input
                name="role"
                type="text"
                className="form-control"
                value={role}
                // onChange={onChange}
                disabled={true}
              />
            </div>
          </div>
          {/* // FIXME: need to fix profile saving and password reset */}
          {/* <div className="row">
            {resetPassword ? (
              <>
                <div className="form-group col-md-3 col-sm-12">
                  <label htmlFor="password">Password</label>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={onChange}
                    required={true}
                  />
                </div>
                <div className="form-group col-md-3 col-sm-12">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <input
                    name="confirmPassword"
                    type="password"
                    className="form-control"
                    value={confirmPassword}
                    onChange={onChange}
                    required={true}
                  />
                </div>
              </>
            ) : (
              <div className="form-group col-6">
                <button
                  className="btn btn-primary"
                  onClick={handleResetPasswordOn}
                >
                  Reset Password
                </button>
              </div>
            )}
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="btn-group float-right">
              <button type="submit" className="btn btn-success">
                Save
              </button>
              <button className="btn btn-secondary" onClick={onClick}>
                Cancel
              </button>
            </div>
          </div> */}
        </>
      ) : (
        <Spinner />
      )}
    </form>
  );
};

export default Profile;
