import React from "react";
import Modal, {
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "../../common/Modal";
import { timeZonesUS } from "../../../utils/timeZones";
import { languagesUSCA } from "../../../utils/languageCodes";

const AddScreen = ({
  show,
  screen,
  handleScreen,
  handleShow,
  addScreen,
  updateScreen,
  disableLocationFields,
  setAlert,
}) => {
  const onChange = (event) => {
    handleScreen({ ...screen, [event.target.name]: event.target.value });
  };

  const onSubmitClick = (event) => {
    event.preventDefault();
    if (!screen._id) {
      addScreen(screen);
      setAlert({ message: `Added Screen: ${screen.name}`, type: "success" });
    } else {
      updateScreen(screen);
      setAlert({ message: `Updated Screen: ${screen.name}`, type: "success" });
    }
    handleShow();
  };

  return (
    <>
      <Modal show={show} onHide={handleShow}>
        <ModalHeader>
          <ModalTitle>{screen._id ? "Update" : "Add"} Screen</ModalTitle>
        </ModalHeader>
        <form onSubmit={onSubmitClick}>
          <ModalBody>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={screen.name || ""}
                onChange={onChange}
                maxLength={85}
                placeholder="85 characters (max)"
                className="form-control"
                required={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="round">Round</label>
              <input
                type="number"
                name="round"
                max={9}
                min={1}
                placeholder="1-9 (max)"
                value={screen.round || ""}
                onChange={onChange}
                className="form-control"
                required={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="timeZone">Time Zone</label>
              <select
                name="timeZone"
                className="form-control"
                onChange={onChange}
                value={screen.timeZone || ""}
                required={true}
                disabled={disableLocationFields}
              >
                <option></option>
                {timeZonesUS.map((timeZone, i) => (
                  <option key={i} value={timeZone}>
                    {timeZone}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="language">Language</label>
              <select
                name="language"
                onChange={onChange}
                className="form-control"
                required={true}
                value={screen.language || ""}
                disabled={disableLocationFields}
              >
                <option></option>
                {languagesUSCA.map((language, i) => (
                  <option key={i} value={language}>
                    {language}
                  </option>
                ))}
              </select>
            </div>
            {screen.authToken ? (
              <>
                <div className="form-group">
                  <label htmlFor="language">Authentication</label>
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      name="authToken"
                      value={screen.authToken || ""}
                      className="form-control"
                      disabled={true}
                    />
                  </div>
                </div>
                {screen.authToken ? (
                  <button
                    className="btn btn-danger"
                    onClick={(event) => {
                      screen.authToken = null;
                      onSubmitClick(event);
                    }}
                  >
                    Disconnect Screen
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-primary">
              {screen._id ? "Update Screen" : "Add Screen"}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default AddScreen;
