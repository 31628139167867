import {
  SET_CURRENT,
  SCREENLINK_ERROR,
  GET_SCREENLINKS,
  DELETE_SCREENLINK,
} from "./screenLinkTypes";

const ScreenLinkReducer = (state, action) => {
  switch (action.type) {
    case GET_SCREENLINKS:
      return {
        ...state,
        screeLinks: action.payload,
        loading: false,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case SCREENLINK_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_SCREENLINK:
      return {
        ...state,
        current: null,
        screenLinks: state.screenLinks.filter(
          (screenlink) => screenlink._id,
          action.payload._id,
        ),
        loading: false,
      };
    default:
      return state;
  }
};

export default ScreenLinkReducer;
