import {
  GET_LOCATIONS,
  SET_CURRENT,
  CLEAR_CURRENT,
  CLEAR_LOCATIONS,
  ADD_LOCATION,
  DELETE_LOCATION,
  GET_LOCATION_CODES,
  CLEAR_LOCATION_CODES,
  LOCATION_ERROR,
  GET_LOCATION_SCHEDULE,
  CLEAR_SCHEDULE,
} from "./locationTypes";

const locationReducer = (state, action) => {
  switch (action.type) {
    case ADD_LOCATION:
      return {
        ...state,
        current: action.payload,
        locations: [action.payload, ...state.locations],
        loading: false,
        error: null,
      };
    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
        loading: false,
        error: null,
      };
    case GET_LOCATION_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
        loading: false,
        error: null,
      };
    case CLEAR_SCHEDULE:
      return {
        ...state,
        error: null,
        schedule: {},
        loading: false,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        locations: state.locations.filter(
          (location) => location.id !== action.payload,
        ),
        loading: false,
        error: null,
      };
    case GET_LOCATION_CODES:
      return {
        ...state,
        codes: [
          action.payload,
          ...state.codes.filter((code) => code.id !== action.payload),
        ],
      };
    case CLEAR_LOCATION_CODES:
      return {
        ...state,
        codes: [],
      };
    case SET_CURRENT:
      return {
        ...state,
        error: null,
        current: action.payload,
        loading: false,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        error: null,
        current: null,
      };
    case CLEAR_LOCATIONS:
      return {
        ...state,
        locations: [],
      };
    case LOCATION_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default locationReducer;
