import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/layout/Navbar";
import AuthContext from "../../context/auth/AuthContext";
import Sidebar from "../../components/layout/Sidebar";
import "./AdminPage.css";
import { Routes, Route } from "react-router-dom";
import UserState from "../../context/user/UserState";
import RoundState from "../../context/round/RoundState";
import WorkoutState from "../../context/workout/WorkoutState";
import ReleaseState from "../../context/release/ReleaseState";
import Footer from "../../components/layout/Footer";
import ScreenState from "../../context/screen/ScreenState";
import LocationState from "../../context/location/LocationState";
import AlertState from "../../context/alert/AlertState";
import AlertContainer from "../../components/layout/AlertContainer";
import LoggerState from "../../context/logger/LoggerState";
import Dashboard from "../../components/admin-page/dashboard/Dashboard";
import Profile from "../../components/admin-page/profile/Profile";
import RoundList from "../../components/admin-page/round/RoundList";
import RoundItem from "../../components/admin-page/round/RoundItem";
import WorkoutItem from "../../components/admin-page/workout/WorkoutItem";
import LocationList from "../../components/admin-page/location/LocationList";
import LocationItem from "../../components/admin-page/location/LocationItem";
import UserList from "../../components/admin-page/user/UserList";
import LoggerList from "../../components/admin-page/logger/LoggerList";
import ReleaseList from "../../components/admin-page/release/ReleaseList";
import UserDetail from "../../components/admin-page/user/UserDetail";
import LocationSchedule from "../../components/admin-page/location/LocationSchedule";

function AdminPage({ ...props }) {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const { isAuthenticated, user, logout, loadUser } = authContext;
  const url = location.pathname;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <AlertState>
      <LoggerState>
        <UserState>
          <RoundState>
            <ReleaseState>
              <Navbar logout={logout} user={user} />
              <div className="container-fluid">
                <div className="row">
                  <Sidebar isAuthenticated={isAuthenticated} user={user} />
                  <main className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                    <AlertContainer location={props.location} />
                    <ScreenState>
                      <WorkoutState>
                        <LocationState>
                          <Routes>
                            <Route
                              path={`admin/dashboard`}
                              element={<Dashboard />}
                            />
                            <Route
                              path={`admin/profile`}
                              element={<Profile />}
                            />
                            <Route
                              path={`admin/rounds`}
                              element={<RoundList />}
                            />
                            <Route
                              path={`admin/rounds/:roundNumber`}
                              element={<RoundItem url={url} />}
                            />
                            <Route
                              exact
                              path={`admin/rounds/:roundNumber/workout/:id`}
                              element={<WorkoutItem />}
                            />
                            <Route
                              path={`admin/rounds/:roundNumber/workout`}
                              element={<WorkoutItem />}
                            />
                            <Route
                              path={`admin/locations`}
                              element={<LocationList />}
                            />
                            <Route
                              path={`admin/locations/:locationId`}
                              element={<LocationItem url={url} />}
                            />
                            <Route
                              path={`admin/locations/:locationId/schedule`}
                              element={<LocationSchedule url={url} />}
                            />
                            <Route
                              path={`admin/users`}
                              element={<UserList />}
                            />
                            <Route
                              path={`admin/users/:userId`}
                              element={<UserDetail url={url} />}
                            />
                            <Route
                              path={`admin/logs`}
                              element={<LoggerList />}
                            />
                            <Route
                              path={`admin/logs`}
                              element={<LoggerList />}
                            />
                            <Route
                              path={`admin/releases`}
                              element={<ReleaseList />}
                            />
                          </Routes>
                        </LocationState>
                      </WorkoutState>
                    </ScreenState>
                    <Footer />
                  </main>
                </div>
              </div>
            </ReleaseState>
          </RoundState>
        </UserState>
      </LoggerState>
    </AlertState>
  );
}

export default AdminPage;
