import React from "react";
import { Link } from "react-router-dom";
import "./z_overwrite.css";

const WorkoutListItem = ({
  workout,
  deleteWorkout,
  setAlert,
  handleStage,
  handleView,
}) => {
  const onDeleteClick = (e) => {
    e.preventDefault();
    const confirmDelete = window.confirm(
      `Are you sure you want to delete workout: "${workout.name}" ?`,
    );
    if (!confirmDelete) return;

    deleteWorkout(workout.id);
    setAlert({ message: `Deleted Workout: ${workout.name}`, type: "success" });
  };

  // Handle vimeo link & staged workout inside window
  const onVimeoClick = (e) => {
    e.preventDefault();
    handleStage(workout, false);
    handleView();
  };

  // Handle lockeroom link & staged workout inside window
  const onLrClick = (e) => {
    e.preventDefault();
    handleStage(workout, true);
    handleView();
  };

  return (
    <>
      {workout && (
        <tr className="workout-item-wrapper">
          <td>{workout.name}</td>
          <td>{workout.startDate}</td>
          <td>{workout.endDate}</td>
          <td>{workout.routines ? workout.routines.length : 0}</td>
          <td>{workout.createdAt}</td>
          <td>{workout.updatedAt}</td>
          <td>
            <div className="btn-group btn-group-sm">
              <Link
                className="btn btn-outline-warning"
                to={`workout/${workout.id}`}
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
              <button
                className="btn btn-outline-danger"
                onClick={onDeleteClick}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
              <button
                title="Vimeo Video"
                className="btn btn-outline-dark"
                onClick={onVimeoClick}
              >
                <i className="fas fa-eye"></i>
              </button>
              <Link
                title="Requires portrait screen"
                target="_blank"
                rel="noopener noreferrer"
                to="/stage"
                onClick={() => handleStage(workout)}
                className="btn btn-outline-info"
              >
                <i className="fas fa-external-link-alt"></i>
              </Link>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default WorkoutListItem;
