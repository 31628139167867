import React, { useEffect, useContext, useState } from "react";
import Spinner from "../../layout/Spinner";
import LocationContext from "../../../context/location/LocationContext";
import AlertContext from "../../../context/alert/AlertContext";
import AddLocation from "./AddLocation";
import LocationListItem from "./LocationListItem";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";
import AuthContext from "../../../context/auth/AuthContext";
import SearchBar from "../../common/SearchBar";
import { checkFlag } from "../../../utils/alertFlag";

const LocationList = (props) => {
  //#region State & Context Management

  const locationContext = useContext(LocationContext);
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const {
    loading,
    error,
    locations,
    addLocation,
    getLocations,
    deleteLocation,
    searchLocations,
  } = locationContext;

  const { setAlert, flag, setFlag, removeFlag } = alertContext;

  const { user } = authContext;

  // Manage Screen in state
  const [location, setLocation] = useState({
    name: null,
    _id: undefined,
    language: null,
    timeZone: null,
    locationIdentifier: null,
  });

  // Manage show state for modal
  const [showAddModal, setShowAddModal] = useState(false);

  // pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 15;

  //#endregion

  //#region Handlers

  // Handle creating a location & set flag for notifications
  const handleCreateLocation = async (location) => {
    setFlag(location, "addLocation");
    addLocation(location);
  };

  // Validate flagged data and display an alert
  const handleFlag = () => {
    checkFlag(flag, setAlert, error);
    removeFlag();
  };

  // const handleUpdateScreen = (screen) => updateScreen(screen);

  const handleLocation = (location) => setLocation(location);
  const handleShow = () => {
    setLocation({
      name: null,
      _id: undefined,
      language: null,
      timeZone: null,
      locationIdentifier: null,
    });
    setShowAddModal(!showAddModal);
  };

  //#endregion

  //#region Lifecyle

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line
  }, [loading, error]);

  // Get current page & update
  useEffect(() => {
    updateResults();
    // eslint-disable-next-line
  }, [page, locations]);

  useEffect(() => {
    if (flag) handleFlag();
    // eslint-disable-next-line
  }, [addLocation]);

  //#endregion

  //#region Functions

  // Update pagination results
  const updateResults = () => {
    paginateResults(locations, page, perPage, setResults);
  };

  // paginate page
  const paginate = (newPage) => {
    setPage(newPage);
  };

  const onAddClick = (event) => {
    event.preventDefault();
    handleShow();
    event.currentTarget.blur();
  };

  const setSearchData = (search) => {
    if (search !== undefined) {
      search = search.toLowerCase();
      searchLocations(search).then(() => {
        setResults(locations);
      });
    }
  };

  //#endregion

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>Locations</h2>
        <SearchBar
          subject="locations"
          setSearchData={setSearchData}
          paginate={paginate}
        />
        <button className="btn btn-success" onClick={onAddClick}>
          Add
        </button>
      </div>
      <AddLocation
        show={showAddModal}
        handleShow={handleShow}
        location={location}
        handleLocation={handleLocation}
        addLocation={handleCreateLocation}
        // updateLocation={handleUpdateLocation}
      />
      {locations && locations.length > 0 && !loading && (
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Studio Number</th>
                <th>Time Zone</th>
                <th>Language</th>
                <th>Active</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {results && !loading && (
              <tbody>
                {results.map((location) => (
                  <LocationListItem
                    key={location.id}
                    location={location}
                    deleteLocation={deleteLocation}
                    setAlert={setAlert}
                    user={user}
                  />
                ))}
              </tbody>
            )}
          </table>
          {locations &&
            !loading &&
            locations.length > perPage &&
            results &&
            results.length > 0 && (
              <Pagination
                currentPage={page}
                perPage={perPage}
                total={locations.length}
                paginate={paginate}
              />
            )}
        </div>
      )}
      <div>
        {!locations || loading ? (
          <Spinner />
        ) : (
          !loading && results && results.length < 1 && <p>No locations found</p>
        )}
      </div>
    </>
  );
};

export default LocationList;
