import { convertTime } from "./timeHandlers";

/**
 * parses class schedule data fetched from db and sorts it by
 * time in a more consistent format for later use
 * 
 * @param   {object} schedule - the schedule object passed through location context
 * @returns {object} - object with class times sorted and converted to 24-hour format
 */
const handleSchedule = (schedule) => {
  if (!schedule) return;
  
  const classTimes = Object.entries(schedule).reduce(
    (acc, [key, value]) => {
      // parse the class time/name strings from the data so that it's easier to work with
      const dayName = key.split("_name_time")[0];
      if (key === dayName + "_name_time") {
        const data = JSON.parse(value) || [];
        const convertedData = data.map((classObj) => ({
          ...classObj,
          class_time: convertTime(classObj.class_time)
        }));
        acc[dayName] = convertedData;
      }
      return acc;
    }, [],
  );
  
  // sort class times earliest first
  Object.values(classTimes).forEach(times => {
    times.sort((a, b) => a.class_time > b.class_time ? 1 : -1)
  })
  
  return classTimes;
};

export default handleSchedule;