import React, { useReducer } from "react";
import LocationContext from "./LocationContext";
import locationReducer from "./locationReducer";
import {
  DELETE_LOCATION,
  LOCATION_ERROR,
  GET_LOCATIONS,
  SET_CURRENT,
  CLEAR_CURRENT,
  ADD_LOCATION,
  CLEAR_LOCATIONS,
  GET_LOCATION_CODES,
  CLEAR_LOCATION_CODES,
  GET_LOCATION_SCHEDULE,
  CLEAR_SCHEDULE,
} from "./locationTypes";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

const LocationState = (props) => {
  const initialState = {
    locations: [],
    schedule: [],
    codes: [],
    current: null,
    loading: true,
    error: null,
  };

  const [state, dispatch] = useReducer(locationReducer, initialState);

  //#region GET

  // Get all Locations
  const getLocations = async () => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get("/api/v1/locations");

      dispatch({ type: GET_LOCATIONS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  const getLocationSchedule = async (locationSlug) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api-v4.ilovekickboxing.com/api/schools/${locationSlug}/schedule`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_ILKB_KEY,
          },
        },
      );
      dispatch({ type: GET_LOCATION_SCHEDULE, payload: res.data });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  const clearSchedule = async () => {
    dispatch({ type: CLEAR_SCHEDULE });
  };

  const searchLocations = async (query = null) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `/api/v1/locations?name=${query}&&locationIdentifier=${query}`,
      );

      dispatch({ type: GET_LOCATIONS, payload: res.data.data });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  // Get a Location
  const getLocation = async (id) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(`/api/v1/locations/${id}`);

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  const getStatusCodes = async (id) => {
    setAuthToken(localStorage.token);
    if (state.codes.includes(id)) return 0;
    try {
      const res = await axios.get(`/api/v1/locations/${id}`);

      dispatch({ type: GET_LOCATION_CODES, payload: res.data.data });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };
  //#endregion

  //#region CREATE

  // Add a Location
  const addLocation = async (location) => {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.post(`/api/v1/locations`, location);

      dispatch({ type: ADD_LOCATION, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: LOCATION_ERROR, payload: err.response.data.error });
      else dispatch({ type: LOCATION_ERROR, payload: err.msg });
    }
  };

  // Add a Screen to Location
  const addLocationScreen = async (screen) => {
    setAuthToken(localStorage.token);
    try {
      const locationId = screen.location;

      // Create the screen
      const newScreen = await axios.post(`/api/v1/screens`, screen);

      // Add new screen to location
      const res = await axios.put(`/api/v1/locations/${locationId}/screens`, {
        screenId: newScreen.data.data._id,
      });

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  //#endregion

  //#region UPDATE
  // Update a Location
  const updateLocation = async (location) => {
    setAuthToken(localStorage.token);

    try {
      const res = await axios.put(
        `/api/v1/locations/${location._id}`,
        location,
      );

      dispatch({ type: SET_CURRENT, payload: res.data.data });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  //#endregion

  //#region DELETE

  // Delete a Location
  const deleteLocation = async (id) => {
    setAuthToken(localStorage.token);
    try {
      await axios.delete(`/api/v1/locations/${id}`);

      dispatch({ type: DELETE_LOCATION, payload: id });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  //#endregion

  //#region Other Methods

  // Set Current Location
  const setCurrent = (id) => {
    try {
      const location = state.locations.find((location) => location.id === id);

      dispatch({ type: SET_CURRENT, payload: location });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  // Clear current
  const clearCurrent = () => {
    try {
      dispatch({ type: CLEAR_CURRENT });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  // Clear current locations
  const clearLocations = () => {
    try {
      dispatch({ type: CLEAR_LOCATIONS });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  const clearStatusCodes = () => {
    try {
      dispatch({ type: CLEAR_LOCATION_CODES });
    } catch (error) {
      dispatch({ type: LOCATION_ERROR, payload: error.msg });
    }
  };

  //#endregion

  return (
    <LocationContext.Provider
      value={{
        error: state.error,
        locations: state.locations,
        schedule: state.schedule,
        codes: state.codes,
        current: state.current,
        loading: state.loading,
        flag: state.flag,
        getLocations,
        getLocationSchedule,
        clearSchedule,
        deleteLocation,
        setCurrent,
        clearCurrent,
        updateLocation,
        addLocation,
        getLocation,
        clearLocations,
        getStatusCodes,
        clearStatusCodes,
        addLocationScreen,
        searchLocations,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationState;
