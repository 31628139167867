import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/AuthContext";

function LoginSSO({ match, history }) {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { loginSSO } = authContext;

  loginSSO(match.params.token);
  navigate("/admin/dashboard");

  return <div></div>;
}

export default LoginSSO;
