import React, { useContext } from "react";
import ReleaseContext from "../../context/release/ReleaseContext";
import { Appversion } from "./Appversion";

const Footer = () => {
  const releaseContext = useContext(ReleaseContext);

  return (
    <p className="mt-5 mb-3 text-muted">
      © {new Date().getFullYear()} Striking Brands LLC.
      <br /> All rights reserved.
      <br />
      {releaseContext && <Appversion />}
    </p>
  );
};

export default Footer;
