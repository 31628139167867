import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../layout/Spinner";
import UserContext from "../../../context/user/UserContext";
import LocationContext from "../../../context/location/LocationContext";
import AlertContext from "../../../context/alert/AlertContext";
import AddUser from "./AddUser";
import AddUserLocation from "./AddUserLocation";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";
import { locationMatch } from "../../../utils/locationMatch";
import { checkFlag } from "../../../utils/alertFlag";
import { Link } from "react-router-dom";
import SearchBar from "../../common/SearchBar";

const UserList = () => {
  //#region Context & State Management

  const alertContext = useContext(AlertContext);
  const { setAlert, flag, setFlag, removeFlag } = alertContext;
  const userContext = useContext(UserContext);
  const {
    users,
    getUsers,
    loading,
    error,
    createUser,
    updateUser,
    deleteUser,
    addLocationToUser,
    roleTypes,
    searchUsers,
  } = userContext;
  const locationContext = useContext(LocationContext);
  const { getLocations, locations } = locationContext;

  // Manage Screen in state
  const [userInput, setUserInput] = useState({
    _id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });

  // Manage add location input
  const [locationInput, setLocationInput] = useState({});

  // Manage show state & data for add location to user modal
  const [addLocation, setAddLocation] = useState(false);

  // Manage show state for add user modal
  const [showAddModal, setShowAddModal] = useState(false);

  // Manage updating user after adding a location
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  // Pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 15;

  //#endregion

  //#region Handlers

  const handleShow = () => {
    setUserInput({
      _id: undefined,
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    });
    setShowAddModal(!showAddModal);
  };

  // Handle creating a user & set flag for notifications
  const handleCreateUser = (userInput) => {
    setFlag(userInput, "addUser");
    createUser(userInput);
  };

  // Handle updating a user
  const handleUpdateUser = (userInput) => updateUser(userInput);

  // Handle input for adding a user
  const handleUserInput = (userInput) => setUserInput(userInput);

  // Handle input for adding existing location to user
  const handleLocationInput = (locationInput) =>
    setLocationInput(locationInput);

  // Handle show add location modal
  const handleLocationShow = () => {
    setAddLocation(false);
  };

  // Handle adding a location to user
  const handleAddLocation = (user, input) => {
    setLoadingUpdate(true);
    addLocationToUser(user.id, input.location).then(() => {
      getUsers().then(() => {
        setLoadingUpdate(false);
      });
    });
    setAlert({
      message: `Added Location to ${user.firstName} ${user.lastName}`,
      type: "success",
    });
  };

  // Validate flagged data and display an alert
  const handleFlag = () => {
    checkFlag(flag, setAlert, error);
    removeFlag();
  };

  //#endregion

  //#region Lifecycle

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [error, loading]);

  // Get current page & update
  useEffect(() => {
    updateResults();
    // eslint-disable-next-line
  }, [page, users]);

  useEffect(() => {
    if (flag) handleFlag();
    // eslint-disable-next-line
  }, [createUser]);

  useEffect(() => {
    getLocations();
    // eslint-disable-next-line
  }, [addLocation]);

  //#endregion

  //#region Functions

  // Paginate page
  const paginate = (newPage) => {
    setPage(newPage);
  };

  // Update pagination results
  const updateResults = () => {
    paginateResults(users, page, perPage, setResults);
  };

  // Add user
  const onAddClick = (event) => {
    event.preventDefault();
    handleShow();
    event.currentTarget.blur();
  };

  // Add location to user
  const onAddLocationClick = (user) => {
    setLocationInput({});
    const availableLocations = locationMatch(locations, user);
    setAddLocation({
      user: user,
      locations: availableLocations,
    });
  };

  // Delete a user
  const onDeleteClick = (user) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to Delete User: ${user.firstName} ${user.lastName} ?`,
    );
    if (!confirmDelete) return;

    deleteUser(user.id);
    setAlert({
      message: `Deleted User: ${user.firstName} ${user.lastName}`,
      type: "success",
    });
  };

  // const onUpdateClick = (event) => {
  //   event.preventDefault();
  //   handleShow();
  //   event.currentTarget.blur();
  // };

  //#endregion

  const setSearchData = (search) => {
    if (search !== undefined) {
      search = search.toLowerCase();
      searchUsers(search).then(() => {
        setResults(users);
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
        <h2>Users (ADMIN)</h2>
        <SearchBar
          subject="users"
          setSearchData={setSearchData}
          paginate={paginate}
        />
        <button
          className="btn btn-success"
          onClick={onAddClick}
          title="Add new user"
        >
          Add
        </button>
      </div>
      <AddUser
        show={showAddModal}
        handleShow={handleShow}
        userInput={userInput}
        handleUser={handleUserInput}
        addUser={handleCreateUser}
        updateUser={handleUpdateUser}
        roleTypes={roleTypes}
      />
      <AddUserLocation
        show={addLocation}
        handleShow={handleLocationShow}
        handleAddLocation={handleAddLocation}
        locationInput={locationInput}
        handleInput={handleLocationInput}
      />
      {users && users.length > 0 && !loading && (
        <div className="table-responsive">
          <table className="table table-striped table-sm">
            <thead>
              <tr>
                <th>Id</th>
                <th>Email</th>
                <th>Name</th>
                <th>Role</th>
                <th>Updated At</th>
                <th></th>
              </tr>
            </thead>
            {results && !loading && (
              <tbody>
                {results.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td>{user.role}</td>
                    <td>{new Date(user.updatedAt).toLocaleString()}</td>
                    <td>
                      <div className="btn-group btn-group-sm">
                        <Link
                          to={`${user._id}`}
                          title={
                            user && user.firstName
                              ? `Edit ${user.firstName}`
                              : "Edit User"
                          }
                          className="btn btn-outline-warning btn-sm"
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </Link>
                        {user.role !== "api-access" && (
                          <button
                            className="btn btn-outline-info btn-sm"
                            title={
                              user && user.firstName
                                ? `Add Location to ${user.firstName}`
                                : "Add Location to User"
                            }
                            onClick={() => onAddLocationClick(user)}
                            disabled={loadingUpdate ? true : false}
                          >
                            <i className="fa fa-map-marker-alt"></i>
                          </button>
                        )}
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => onDeleteClick(user)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {users &&
            !loading &&
            users.length > perPage &&
            results &&
            results.length > 0 && (
              <Pagination
                currentPage={page}
                perPage={perPage}
                total={users.length}
                paginate={paginate}
              />
            )}
        </div>
      )}
      {loading && <Spinner />}
      {!loading && results && results.length < 1 && <p>No users found</p>}
      {}
    </>
  );
};

export default UserList;
