import React, { useState, useEffect, useContext, useRef } from "react";
import WorkoutContext from "../../../context/workout/WorkoutContext";
import ContentStatusListItem from "./ContentStatusListItem";
import Pagination from "../../common/Pagination";
import paginateResults from "../../../utils/paginateResults";

const ContentStatus = () => { 

  const workoutContext = useContext(WorkoutContext);
  const {
      loading,
      workouts,
      getWorkoutsForRound,
      error,
      clearCurrent,
      clearWorkouts,
  } = workoutContext;

  // Routine status consts
  const [missingVideos, setMissingVideos] = useState(0);
  const [workoutsMissingVideos, setWorkoutsMissingVideos] = useState();

  // Pagination state
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const perPage = 5;

  // For fetching class workouts
  const roundNumber = 1;
  
  useEffect(() => {
    let videosMissing = 0;
    const filteredWorkouts = workouts.filter(workout => {
      const numMissing = workout.routines.filter(routine => routine.videoLink === "").length;
      if (numMissing > 0) {
        videosMissing += numMissing;
        return true;
      }
      return false;
    });

    setMissingVideos(videosMissing)
    setWorkoutsMissingVideos(filteredWorkouts)
  }, [workouts, loading])
  
  useEffect(() => {
    getWorkoutsForRound(roundNumber);
    clearCurrent();
  
    // Clear workouts on unmount
    return () => {
      clearWorkouts();
    };
    
    // eslint-disable-next-line
  }, [error, loading]);
  
  useEffect(() => {
    updateResults();
    // eslint-disable-next-line
  }, [page, workoutsMissingVideos]);
  
  // update pagination results
  const updateResults = () => {
    paginateResults(workoutsMissingVideos, page, perPage, setResults);
  };
  
  const paginate = (newPage) => {
    setPage(newPage);
  };

  // Column field types
  const fieldTypes = [
    { name: "Name" },
    { startDate: "Start Date" },
    { endDate: "End Date" },
    { routines: "Routines" },
  ];

  return (
    <>
      <div className="content-status-container pb-4">
        <h3 style={{ display: "inline-block" }}>
          <span>Content Status</span>
        </h3>
        {" "}
        {missingVideos > 0 ? (
          <>
            <span>
              <i
                className="fas fa-times-circle text-danger"
                title="Missing routines detected"
              ></i>
            </span>
            <p>(Missing Videos: {missingVideos})</p>
            {workouts?.length > 0 && !loading && (
              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      {fieldTypes.map((field, index) => (
                        <th key={index}>{Object.values(field)} </th>
                      ))}
                      <th></th>
                    </tr>
                  </thead>
                    <tbody>
                      {results?.map((workout, index) => (
                        <ContentStatusListItem 
                          key={index}
                          id={workout.id}
                          workout={workout}
                        />
                      ))}
                    </tbody>
                </table>
                {workoutsMissingVideos?.length > perPage && (
                    <Pagination
                      currentPage={page}
                      perPage={perPage}
                      total={workoutsMissingVideos.length}
                      paginate={paginate}
                    />
                  )}
              </div>
            )}
          </>
        ) : (
          <span>
            <i 
              className="fas fa-check-circle text-success"
            ></i>
          </span>
        )}
      </div>
    </>
  );
  
};

export default ContentStatus;
