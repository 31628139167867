import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/auth/AuthContext";
import { Outlet, Navigate } from "react-router-dom";
import Spinner from "../layout/Spinner";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user, loadUser } = authContext;

  useEffect(() => {
    loadUser();
    if (isAuthenticated) console.log("IS AUTHENTICATED");
    // eslint-disable-next-line
  }, []);

  if (!localStorage.getItem("token")) {
    return (<Navigate replace to="/login" />)
  }

  if (!user || loading) {
    return <Spinner />;
  }

  return isAuthenticated ? (
    <Outlet /> // Render child routes if authenticated
  ) : (
    <Navigate replace to="/login" /> // Redirect to login if not authenticated
  );
};

export default PrivateRoute;
