import React from "react";
import { Link } from "react-router-dom";

const RoundListItem = ({ round, match }) => {
  return (
    <div
      className="col-lg-3 pb-4 mb-2"
      style={{ maxWidth: "24rem" }}
    >
      <div className="card card">
        <div className="card-header text-center pb-0 mb-0">
          <h1 className="text-dark" style={{ fontSize: "1.3rem" }}>
            {round.name}
          </h1>
        </div>
        <Link
          className="btn bg-light text-dark"
          to={`/admin/rounds/${round.roundNumber}`}
        >
          <div
            className="card-body text-center bg-dark"
            style={{ borderRadius: ".2rem" }}
          >
            <img
              src={round.imageUrl}
              alt={round.name}
              style={{
                maxHeight: "100px",
                maxWidth: "100px",
                borderRadius: "1rem",
              }}
            />
          </div>
          <i className="fas fa-eye mt-3">
            <small
              style={{
                marginLeft: ".5rem",
                fontSize: "1.2rem",
                fontFamily: "Avenir Medium",
              }}
            >
              View
            </small>{" "}
          </i>
        </Link>
      </div>
    </div>
  );
};

export default RoundListItem;
