import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UserContext from "../../../context/user/UserContext";
import LockButton from "../../common/LockButton";
import BackButton from "../../common/BackButton";
import Spinner from "../../layout/Spinner";
import UserLocationList from "./UserLocationList";
import AlertContext from "../../../context/alert/AlertContext";
// import LocationScreenList from "./LocationScreenList";

const UserDetail = ({ url }) => {
  const params = useParams();
  const userId = params ? params.userId : undefined;
  const userContext = useContext(UserContext);
  const {
    error,
    loading,
    current,
    clearCurrent,
    getUser,
    updateUser,
    removeUserLocation,
    addUserLocation,
    roleTypes,
  } = userContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [userInput, setUserInput] = useState({
    _id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    locations: [],
  });

  const [locked, setLocked] = useState(true);

  const handleUserInput = (userInput) => {
    setUserInput({ ...current, ...userInput });
  };

  const handleCreateLocation = (location) => {
    console.log(current);
    addUserLocation(current._id, location);
  };

  useEffect(() => {
    if (!current) {
      getUser(userId);
    }

    handleUserInput(current);

    // eslint-disable-next-line
  }, [error, current, loading]);

  // On unmount clear current
  useEffect(() => {
    return () => clearCurrent();
    // eslint-disable-next-line
  }, []);

  const onSubmitClick = (event) => {
    event.preventDefault();

    updateUser(userInput);

    setLocked(true);
    event.currentTarget.blur();
  };

  const onChange = (event) => {
    let value = null;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    handleUserInput({
      ...userInput,
      [event.target.name]: value,
    });
  };

  return (
    <>
      {current && !loading && (
        <>
          <form>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h2 className="text-break">
                <LockButton locked={locked} setLocked={setLocked} />{" "}
                {userInput.firstName + " " + userInput.lastName}
              </h2>
              <div className="btn-group ">
                <BackButton url={url} />
                {!locked && (
                  <button className="btn btn-primary" onClick={onSubmitClick}>
                    <i className="fas fa-save"></i> Update
                  </button>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label htmlFor="">First Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  placeholder="32 characters (max)"
                  value={userInput.firstName || ""}
                  onChange={onChange}
                  maxLength={32}
                  disabled={locked}
                  required={true}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="">Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  placeholder="32 characters (max)"
                  value={userInput.lastName || ""}
                  onChange={onChange}
                  maxLength={32}
                  disabled={locked}
                  required={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-3">
                <label htmlFor="">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={userInput.email || ""}
                  onChange={onChange}
                  disabled={locked}
                  required={true}
                />
              </div>
              <div className="form-group col-3">
                <label htmlFor="language">Role</label>
                <select
                  className="form-control"
                  name="role"
                  onChange={onChange}
                  disabled={locked}
                  required={true}
                  value={userInput.role || ""}
                >
                  <option></option>
                  {roleTypes.map((role, i) => (
                    <option key={i} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="checkbox"
                  name="isActive"
                  className="form-check-input"
                  checked={userInput.isActive || false}
                  onChange={onChange}
                  disabled={locked}
                />
                <label htmlFor="isActive" className="form-check-label">
                  Active
                </label>
              </div>
            </div>
          </form>
          <UserLocationList
            locked={locked}
            locations={userInput.locations}
            handleCreateLocation={handleCreateLocation}
            removeUserLocation={removeUserLocation}
            setAlert={setAlert}
            userId={userId}
            loading={loading}
          />
        </>
      )}
      {(!current || loading) && <Spinner />}
    </>
  );
};

export default UserDetail;
