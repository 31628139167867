import {
  GET_WORKOUTS,
  GET_CONTENT,
  SET_CURRENT,
  CLEAR_CURRENT,
  CLEAR_WORKOUTS,
  ADD_WORKOUT,
  UPDATE_WORKOUT,
  DELETE_WORKOUT,
  SORT_ORDER,
  CLEAR_CONTENT,
} from "./workoutTypes";
import tableSort from "../../utils/tableSort";

const workoutReducer = (state, action) => {
  switch (action.type) {
    case ADD_WORKOUT:
      return {
        ...state,
        current: action.payload,
        workouts: [...state.workouts, action.payload],
        loading: false,
      };
    case UPDATE_WORKOUT:
      return {
        ...state,
        current: action.payload,
        workouts: state.workouts.map((workout) =>
          workout._id === action.payload._id ? action.payload : workout,
        ),
        loading: false,
      };
    case GET_WORKOUTS:
      return {
        ...state,
        workouts: action.payload,
        loading: false,
      };
    case GET_CONTENT:
      return {
        ...state,
        currentContent: action.payload,
        loading: false,
      };
    case DELETE_WORKOUT:
      return {
        ...state,
        workouts: state.workouts.filter(
          (workout) => workout.id !== action.payload,
        ),
        loading: false,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case SORT_ORDER:
      const { direction, field, exceptions } = action.payload;
      return {
        ...state,
        workouts: state.workouts.sort((a, b) =>
          tableSort(direction, field, exceptions, a, b),
        ),
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case CLEAR_WORKOUTS:
      return {
        ...state,
        workouts: [],
      };
    case CLEAR_CONTENT:
      return {
        ...state,
        currentContent: [],
      };
    default:
      return state;
  }
};

export default workoutReducer;
