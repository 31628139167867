import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/layout/Footer";
import AuthContext from "../../context/auth/AuthContext";
import "./login.css";

function Login(props) {
  const authContext = useContext(AuthContext);

  const { login, error, clearErrors, isAuthenticated, loading } = authContext;

  const navigate = useNavigate();

  // useEffect: Check authentication credentials and move to dashboard route on successful login.

  useEffect(() => {
    if (isAuthenticated) {
      clearErrors();
      navigate("/admin/dashboard");
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history, loading]);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { email, password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login({ email, password });
  };

  return (
    <div className="center">
      <form className="form-signin" onSubmit={onSubmit}>
        <img
          className="mb-4"
          src="https://res.cloudinary.com/ilovekickboxing/image/upload/v1730998562/ilkb-logo-dark-blue_i5swwo.png"
          alt=""
          width="300"
        />
        <h1 className="h4 mb-3 font-weight-bold">
        {global.config.product.app.name}
        </h1>

        {error ? (
          <div className="alert alert-danger">
            <i className="fas fa-exclamation-circle"> </i> {error}
          </div>
        ) : (
          ""
        )}

        <label htmlFor="inputEmail" className="sr-only">
          Email
        </label>
        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="Email"
          onChange={onChange}
          required
        />
        <label htmlFor="inputPassword" className="sr-only">
          Password
        </label>
        <input
          type="password"
          name="password"
          className="form-control"
          placeholder="Password"
          onChange={onChange}
          required
        />
        {/* <div className="checkbox mb-3">
          <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label>
        </div> */}
        <input
          className="btn btn-lg btn-9r-red btn-block"
          type="submit"
          value="Login"
          style={{backgroundColor: "#0a072e"}}
        />
        <Footer />
      </form>
    </div>
  );
}

export default Login;
