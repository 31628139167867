import React from "react";
import WorkoutList from "../workout/WorkoutList";
import { useParams } from "react-router-dom";

const RoundItem = ({ url }) => {
  let { roundNumber } = useParams();

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h2>
          <b style={{ letterSpacing: "2px" }}>
            Class Screen{" "}
          </b>
          {roundNumber}
        </h2>
      </div>
      <WorkoutList roundNumber={roundNumber} url={url} />
    </>
  );
};

export default RoundItem;
