import {
  ROUND_ERROR,
  GET_ROUNDS,
  ADD_ROUND,
  DELETE_ROUND,
  UPDATE_ROUND,
} from "./roundTypes";
const roundReducer = (state, action) => {
  switch (action.type) {
    case GET_ROUNDS:
      return {
        ...state,
        rounds: action.payload,
        loading: false,
      };
    case ROUND_ERROR:
      return { ...state, error: action.payload };
    case ADD_ROUND:
      return {
        ...state,
        loading: false,
        rounds: [action.payload, ...state.rounds],
      };
    case UPDATE_ROUND:
      return {
        ...state,
        rounds: state.rounds.map((round) =>
          round._id === action.payload._id ? action.payload : round,
        ),
        loading: false,
      };
    case DELETE_ROUND:
      return {
        ...state,
        rounds: state.rounds.filter((round) => round._id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};

export default roundReducer;
